<template>
  <section>
    <div class="dashboard-header">
      <div class="flex">
        <h2 id="templates-heading" class="text-med serif">Templates</h2>
        <p class="text-small">
          Templates help provide support to writers in the drafting stage of the writing process.
        </p>
      </div>
      <div class="button-container flex">
        <button
          ref="joinTemplateButton"
          @click="showJoinTemplateModal = true"
          aria-haspopup="dialog"
          class="button-secondary"
        >
          Join a Template
        </button>
        <button
          ref="createTemplateButton"
          @click="showCreateTemplateModal = true"
          aria-haspopup="dialog"
          class="button"
        >
          Create a Template
        </button>
      </div>
    </div>
    <ul class="all-templates" v-if="templates.length > 0" aria-labelledby="templates-heading">
      <template-card v-for="template in templates" :key="template.id" :template="template" />
    </ul>
    <div class="empty-state" v-else>
      <img alt="" class="empty-image" src="/static/images/empty-states/empty-examples.png" />
      <p class="text-med serif">No Templates Found!</p>
      <p>If you are an <b>instructor,</b> create templates to help guide your writers as they create their draft.</p>
      <p>If you are a <b>student,</b> ask your instructor for an invite through a code, link, or email. Typically, you won’t be creating templates yourself as a student.</p>
    </div>
    <template-modal-create
      v-if="showCreateTemplateModal"
      @close="
        showCreateTemplateModal = false;
        $refs.createTemplateButton.focus();
      "
      @created-template="handleNewTemplateCompletion"
    ></template-modal-create>
    <template-modal-join
      v-if="showJoinTemplateModal"
      @close="
        showJoinTemplateModal = false;
        $refs.joinTemplateButton.focus();
      "
      @joined-template="handleJoinedTemplate"
    ></template-modal-join>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";

import TemplateCard from "shared/components/template/TemplateCard.vue";
import TemplateModalCreate from "shared/components/template/TemplateModalCreate.vue";
import TemplateModalJoin from "shared/components/template/TemplateModalJoin.vue";

export default {
  components: {
    TemplateCard,
    TemplateModalCreate,
    TemplateModalJoin,
  },
  data() {
    return {
      showCreateTemplateModal: false,
      showJoinTemplateModal: false,
    };
  },
  computed: {
    ...mapState("template", {
      templates: "allTemplates",
    }),
  },
  methods: {
    ...mapActions(["showSnackbar"]),
    handleNewTemplateCompletion(template) {
      this.showCreateTemplateModal = false;
      this.showSnackbar(`Successfully created ${template.name}`);
      this.$router.push({ name: "Template", params: { templateId: template.id } });
    },
    handleJoinedTemplate(template) {
      this.showJoinTemplateModal = false;
      this.showSnackbar(`Successfully joined ${template.name}`);
      this.$router.push({ name: "Template", params: { templateId: template.id } });
    },
  },
};
</script>
