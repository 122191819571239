<template>
  <li @click="highlight" v-if="exists">
    <a href="#">{{ tag.title }}</a>
  </li>
  <li class="disabled-tag" v-else>{{ tag.title }}</li>
</template>

<script>
export default {
  name: "ExampleTagChecklistItem",
  props: {
    tag: {
      type: Object,
      required: true,
    },
    exampleTag: {
      type: Object,
      required: false,
      default: null,
    },
  },
  computed: {
    exists() {
      return this.exampleTag != null;
    },
  },
  methods: {
    highlight() {
      this.$emit("highlight", this.exampleTag);
    },
  },
};
</script>
