<template>
  <ul class="all-templates" aria-label="All Templates">
    <template-card v-for="template in templates" :key="template.id" :template="template" />
    <template-card-create :collection-id="collectionId"></template-card-create>
  </ul>
</template>

<script>
import TemplateCard from "shared/components/template/TemplateCard.vue";
import TemplateCardCreate from "shared/components/template/TemplateCardCreate.vue";

export default {
  name: "CollectionDetailTemplates",

  components: {
    TemplateCard,
    TemplateCardCreate,
  },

  props: {
    templates: {
      type: Array,
      required: true,
    },
    collectionId: {
      type: Number,
      required: true,
    },
  },
};
</script>
