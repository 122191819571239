import {
  SET_EMAIL,
  SET_CURRENT_DRAFT_TAGS,
  SET_DRAFT,
  SET_DRAFT_FEEDBACK,
  SET_SELECTED_CHECKLIST_ITEM_IDS,
  SET_IS_TAGGING,
  SET_CURRENT_SELECTION,
  SET_IS_DISPLAYING_ALL_TAGS,
  SET_IS_IN_GUEST_FEEDBACK,
} from "./mutations";
import DraftService from "../../../services/drafts";
import EmailService from "../../../services/email";
import EmailExampleClickedService from "../../../services/email-example-clicked";
import EmailFeedbackService from "../../../services/email-feedback";
import EmailTagService from "../../../services/email-tag";

const state = () => ({
  currentDraft: null,
  currentDraftTags: [],
  currentEmail: null,
  currentSelection: { start: 0, end: 0, content: "" },
  selectedChecklistItemIds: [],
  isDisplayingAllTags: true,
  isInGuestFeedback: false,
  isTagging: false,
  feedback: [],
});

const getters = {
  draftTagsToBeDisplayed: (state, getters, rootState) => {
    const selectedItemIds = state.selectedChecklistItemIds;
    const isDisplayingAllTags = state.isDisplayingAllTags;
    const items = rootState.template.checklistItems;
    const currentDraftTags = state.currentDraftTags;

    const tagsWithColor = currentDraftTags.map((tag) => {
      const checklistItem = items.find((item) => item.id === tag.template_checklist_item_id);
      return { ...tag, color: checklistItem.color };
    });

    if (!isDisplayingAllTags && selectedItemIds.length === 0) {
      return [];
    }

    if (isDisplayingAllTags && selectedItemIds.length === 0) {
      return tagsWithColor;
    } else {
      const foundTags = tagsWithColor.filter((tag) =>
        selectedItemIds.includes(tag.template_checklist_item_id)
      );
      return foundTags;
    }
  },

  getExampleTagsToBeDisplayed: (state, getters, rootState) => (example) => {
    const selectedItemIds = state.selectedChecklistItemIds;
    const checklistItems = rootState.template.checklistItems;
    const tags = example.tags ?? [];
    const tagsWithColor = tags.map((tag) => {
      const checklistItem = checklistItems.find(
        (item) => item.id === tag.template_checklist_item_id
      );
      return { ...tag, color: checklistItem.color };
    });
    const isDisplayingAllTags = state.isDisplayingAllTags;

    if (!isDisplayingAllTags && selectedItemIds.length === 0) {
      return [];
    }

    if (isDisplayingAllTags && selectedItemIds.length === 0) {
      return tagsWithColor;
    } else {
      const foundTags = tagsWithColor.filter((tag) =>
        selectedItemIds.includes(tag.template_checklist_item_id)
      );
      return foundTags;
    }
  },

  taggedChecklistItemIds: (state) => {
    const tags = state.currentDraftTags;
    return tags.map((tag) => tag.template_checklist_item_id);
  },

  allRequiredChecklistItemsAreTagged: (state, getters, rootState) => {
    const allItems = rootState.template.checklistItems;
    const currentDraftTags = state.currentDraftTags;

    const requiredItemIds = allItems
      .filter((item) => item.required)
      .map((item) => item.id)
      .sort();
    if (requiredItemIds.length == 0) return true;

    const taggedChecklistItemIds = currentDraftTags
      .map((tag) => tag.template_checklist_item_id)
      .sort();

    return requiredItemIds.every((id) => taggedChecklistItemIds.includes(id));
  },
};

const mutations = {
  [SET_DRAFT](state, draft) {
    state.currentDraft = draft;
  },
  [SET_EMAIL](state, email) {
    state.currentEmail = email;
  },
  [SET_IS_TAGGING](state, isTagging) {
    state.isTagging = isTagging;
  },
  [SET_IS_DISPLAYING_ALL_TAGS](state, isShowingAllTags) {
    state.isDisplayingAllTags = isShowingAllTags;
  },
  [SET_CURRENT_DRAFT_TAGS](state, tags) {
    state.currentDraftTags = tags;
  },
  [SET_CURRENT_SELECTION](state, selection) {
    state.currentSelection = selection;
  },
  [SET_SELECTED_CHECKLIST_ITEM_IDS](state, itemIds) {
    state.selectedChecklistItemIds = itemIds;
  },
  [SET_DRAFT_FEEDBACK](state, feedback) {
    state.feedback = feedback;
  },
  [SET_IS_IN_GUEST_FEEDBACK](state, value) {
    state.isInGuestFeedback = value;
  },
};

const actions = {
  // Draft-related actions

  setCurrentDraft({ commit }, draft) {
    commit(SET_DRAFT, draft);
  },

  setCurrentEmail({ commit }, email) {
    commit(SET_EMAIL, email);
    if (email && email.tags) {
      commit(SET_CURRENT_DRAFT_TAGS, email.tags);
    }
  },

  async getDraft({ dispatch }, slug) {
    try {
      const response = await DraftService.load(slug);
      if (response.status == 200) {
        const draft = response.data;
        dispatch("setCurrentDraft", draft);
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },

  async initEmail({ dispatch, rootState, rootGetters, state }) {
    const currentDraft = state.currentDraft;
    let email = null;

    if (currentDraft && currentDraft.slug) {
      try {
        const { data: result } = await EmailService.show(currentDraft.slug);
        if (result.success) {
          email = result.data;
        } else {
          return false;
        }
      } catch (error) {
        if (error.status != 404) {
          return false;
        }
      }
    }

    if (!email) {
      email = {
        id: null,
        draft_id: currentDraft.id,
        slug: currentDraft.slug,
        user_id: rootState.user.id,
        template_id: rootGetters["template/templateId"],
        subject: "(Subject/Title)",
        content: "",
        created_at: new Date(),
        updated_at: new Date(),
        tags: [],
      };
      dispatch("setDraftFeedback", []);
    }
    dispatch("setCurrentEmail", email);
    return true;
  },

  async saveEmail({ state, dispatch }, emailUpdates) {
    const { data: result } = await EmailService.store({
      ...state.currentEmail,
      ...emailUpdates,
    });
    dispatch("setCurrentEmail", result.data);
  },

  async updateEmail({ state, dispatch }, emailUpdates) {
    const { data: result } = await EmailService.update(state.currentEmail.id, {
      ...state.currentEmail,
      ...emailUpdates,
    });
    dispatch("setCurrentEmail", result.data);
  },

  async updateOrSaveEmail({ state, dispatch }, emailUpdates) {
    if (emailUpdates.subject && emailUpdates.content) {
      if (state.currentEmail.id && state.currentEmail.id > 0) {
        await dispatch("updateEmail", emailUpdates);
      } else {
        await dispatch("saveEmail", emailUpdates);
      }
    }
  },

  async removeEmail({ dispatch }, email) {
    const { data: result } = await EmailService.destroy(email.id);
    if (result.success) {
      dispatch("template/removeEmailFromTemplate", email, { root: true });
    }
    return result;
  },

  // Tags-related actions
  setIsTagging({ commit }, isTagging) {
    commit(SET_IS_TAGGING, isTagging);
  },

  setIsDisplayingAllTags({ commit }, isDisplayingAllTags) {
    commit(SET_IS_DISPLAYING_ALL_TAGS, isDisplayingAllTags);
  },

  setCurrentSelection({ commit }, selection) {
    commit(SET_CURRENT_SELECTION, selection);
  },

  resetCurrentSelection({ commit }) {
    const emptySelection = { start: 0, end: 0, content: "" };
    commit(SET_CURRENT_SELECTION, emptySelection);
  },

  setCurrentDraftTags({ commit }, tags) {
    commit(SET_CURRENT_DRAFT_TAGS, tags);
  },

  setSelectedChecklistItemId({ commit }, itemId) {
    commit(SET_SELECTED_CHECKLIST_ITEM_IDS, [itemId]);
  },

  restoreSelectedChecklistItems({ commit }) {
    commit(SET_SELECTED_CHECKLIST_ITEM_IDS, []);
  },

  async saveTag({ state, dispatch }, tagPayload) {
    const { data: result } = await EmailTagService.store(tagPayload);
    const tagResponse = result.data;
    const tags = [...state.currentDraftTags, tagResponse];
    dispatch("setCurrentDraftTags", tags);
  },

  async updateTag({ state, dispatch }, { tagId, tagPayload }) {
    await EmailTagService.update(tagId, tagPayload);
    const tags = state.currentDraftTags.map((t) => {
      if (t.id === tagId) {
        return { ...t, ...tagPayload };
      } else {
        return t;
      }
    });
    dispatch("setCurrentDraftTags", tags);
  },

  async updateTags({ state, commit }, tagList) {
    const responses = await Promise.all(
      tagList.tags.map((tag) => EmailTagService.update(tag.id, tag))
    );

    responses.forEach(({ data: result }) => {
      if (result.success) {
        const updatedTag = result.data;
        const newTags = state.currentDraftTags.map((t) => {
          if (t.id === updatedTag.id) {
            return updatedTag;
          } else {
            return t;
          }
        });
        commit(SET_CURRENT_DRAFT_TAGS, newTags);
      }
    });
  },

  async removeTag({ state, dispatch }, tagId) {
    await EmailTagService.destroy(tagId);
    const tags = state.currentDraftTags.filter((t) => t.id !== tagId);
    dispatch("setCurrentDraftTags", tags);
  },

  // Email-example-related actions

  async recordEmailExampleClicked({ state }, example) {
    if (state.currentEmail.id) {
      await EmailExampleClickedService.store({
        email_id: state.currentEmail.id,
        example_email_id: example.id,
      });
    }
  },

  // Draft-feedback-related actions
  setInGuestFeedback({ commit }, value) {
    commit(SET_IS_IN_GUEST_FEEDBACK, value);
  },

  setDraftFeedback({ commit }, feedback) {
    commit(SET_DRAFT_FEEDBACK, feedback);
  },

  async getDraftWithFeedback({ state, commit, dispatch, rootState }, slug) {
    const { data: result } = await EmailFeedbackService.show(slug, rootState.isGuest);

    if (result.success) {
      if (!state.currentEmail || state.currentEmail.updated_at !== result.data.email.updated_at) {
        dispatch("setCurrentEmail", result.data.email);
      }
      commit(SET_DRAFT_FEEDBACK, result.data.feedback);
    }
  },

  async getGuestLoginPayload({ rootState }, slug) {
    const { data: result } = await EmailFeedbackService.show(slug, rootState.isGuest);

    if (rootState.isGuest) {
      const guestLoginPayload = {
        token: result.data.token,
        refresh: result.data.refresh,
        user: result.data.user,
      };
      return guestLoginPayload;
    }
    return null;
  },

  async saveEmailFeedback({ state, commit }, { payload, useGuestHeader }) {
    const { data: result } = await EmailFeedbackService.store(payload, useGuestHeader);
    if (result.success) {
      const newFeedbackList = [...state.feedback, result.data];
      commit(SET_DRAFT_FEEDBACK, newFeedbackList);
    }
  },

  async setEmailFeedbackResolution({ state, commit }, { feedbackId, isResolved }) {
    const { data: result } = await EmailFeedbackService.setResolution(
      feedbackId, isResolved
    );
    const newFeedbackList = [...state.feedback];
    const idx = newFeedbackList.findIndex(feedback => feedback.id === result.id);
    newFeedbackList[idx] = result;
    commit(SET_DRAFT_FEEDBACK, newFeedbackList);
  },
};

export const store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default store;
