<template>
  <ls-modal @close="$emit('close')" :visible="true">
    <template #content>
      <h2 id="dialog_label" class="text-med">Create a Collection</h2>
      <div class="modal-body">
        <div class="modal-form-field">
          <label for="create-a-collection-modal-collection-name" class="form-label bold">
            Collection Name
          </label>
          <input
            class="input"
            id="create-a-collection-modal-collection-name"
            type="text"
            v-model="collectionName"
          />
        </div>
        <div class="modal-form-field">
          <div class="form-label bold">
            Which templates would you like to place inside this collection?
          </div>
          <p class="description-text">Existing templates:</p>
          <div class="template-list">
            <label
              v-for="template in allTemplates"
              :key="template.id"
              :class="{ disabled: !$can('change', template) }"
            >
              <input
                type="checkbox"
                :value="template.id"
                v-model="includeTemplates"
                :disabled="!$can('change', template)"
              />
              {{ template.name }}
            </label>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <button class="button" @click="handleCreateCollection" :disabled="!allowedToCreate">
        Create Collection
      </button>
    </template>
  </ls-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Modal from "shared/components/Modal.vue";

export default {
  name: "CreateCollectionModal",
  components: {
    "ls-modal": Modal,
  },
  data() {
    return {
      collectionName: "",
      includeTemplates: [],
      submitted: false,
    };
  },
  computed: {
    ...mapState("template", { allTemplates: "allTemplates" }),

    allowedToCreate() {
      return this.collectionName.trim() != "" && !this.submitted;
    },
  },
  methods: {
    ...mapActions("collection", { createCollection: "createCollection" }),
    ...mapActions("template", { getTemplates: "getTemplates" }),
    handleCreateCollection() {
      this.submitted = true;

      this.createCollection({
        collection: { name: this.collectionName, templates: this.includeTemplates },
      }).then((collection) => {
        return this.getTemplates().then(() => {
          this.$emit("created-collection", collection);
        });
      });
    },
  },
};
</script>
