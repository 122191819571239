<template>
  <section>
    <div class="dashboard-header">
      <div class="flex">
        <h2 id="collections-heading" class="text-med serif">Collections</h2>
        <p class="text-small">
          Collections help organize different types of writing templates together.
        </p>
      </div>
      <div class="button-container flex">
        <button
          ref="joinCollectionButton"
          @click="showJoinCollectionModal = true"
          aria-haspopup="dialog"
          class="button-secondary"
        >
          Join a Collection
        </button>
        <button
          @click="showCreateCollectionModal = true"
          class="button"
          aria-haspopup="dialog"
          ref="collectionModalTrigger"
        >
          Create a Collection
        </button>
      </div>
    </div>
    <ul class="all-collections" v-if="collections.length > 0" aria-labelledby="collections-heading">
      <collection-card
        v-for="collection in collections"
        :key="collection.id"
        :collection="collection"
      />
    </ul>
    <div class="empty-state" v-else>
      <img alt="" class="empty-image" src="/static/images/empty-states/empty-collections.png" />
      <p class="text-med serif">No Collections Found!</p>
      <p>If you are an <b>instructor,</b> create collections to organize your related templates together.</p>
      <p>If you are a <b>student,</b> join a collection by asking your instructor for an invite through a code, link, or email. Typically, you won’t be creating these yourself as a student.</p>
    </div>

    <create-collection-modal
      v-if="showCreateCollectionModal"
      @close="hideAndRefocus"
      @created-collection="handleNewCollectionCompletion"
    />
    <collection-modal-join
      v-if="showJoinCollectionModal"
      @close="showJoinCollectionModal = false; $refs.joinCollectionButton.focus()"
      @joined-collection="handleJoinedCollection"
    />
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CollectionCard from "./CollectionCard.vue";
import CreateCollectionModal from "./CreateCollectionModal.vue";
import CollectionModalJoin from '../components/CollectionModalJoin.vue'

export default {
  name: "CollectionsOverview",
  components: {
    CollectionCard,
    CollectionModalJoin,
    CreateCollectionModal,
  },
  data() {
    return {
      showCreateCollectionModal: false,
      showJoinCollectionModal: false,
    };
  },
  computed: {
    ...mapState("collection", { collections: "collections" }),
  },
  methods: {
    ...mapActions(["showSnackbar"]),

    handleNewCollectionCompletion(collection) {
      this.showCreateCollectionModal = false;
      this.showSnackbar(`Successfully created ${collection.name}`);
      this.$router.push({ name: "Collection", params: { collectionId: collection.id } });
    },
    handleJoinedCollection(collection) {
      this.showJoinCollectionModal = false;
      this.showSnackbar(`Successfully joined ${collection.name}`);
      this.$router.push({ name: "Collection", params: { collectionId: collection.id } });
    },
    hideAndRefocus() {
      this.showCreateCollectionModal = false;
      this.$refs.collectionModalTrigger.focus();
    },
  },
};
</script>
