export default {
  inserted: function (el) {
    let focusableEls = el.querySelectorAll(
      "a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input:not([disabled]), select:not([disabled]), p[tabindex]"
    );
    let firstFocusableEl = focusableEls[0];
    let firstFocusableInput = el.querySelector(
      "textarea:not([disabled]), input:not([disabled]), select:not([disabled])"
    );
    let lastFocusableEl = focusableEls[focusableEls.length - 1];
    let KEYCODE_TAB = 9;

    el.addEventListener("keydown", function (e) {
      let isTabPressed = e.key === "Tab" || e.keyCode === KEYCODE_TAB;

      if (!isTabPressed) {
        return;
      }

      if (e.shiftKey) {
        /* shift + tab */ if (document.activeElement === firstFocusableEl) {
          lastFocusableEl.focus();
          e.preventDefault();
        }
      } /* tab */ else {
        if (document.activeElement === lastFocusableEl) {
          firstFocusableEl.focus();
          e.preventDefault();
        }
      }
    });

    if (firstFocusableInput) {
      firstFocusableInput.focus();
    } else if (firstFocusableEl) {
      firstFocusableEl.focus();
    }
  },
};
